
import React from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PatientInfo from "./Screens/PatientInfo";
import PatientDetails from "./Screens/PatientDetails";
import PatientDataDisplay from "./Screens/PatientDataDisplay";
import AddDoctors from "./Screens/AddDoctors";
import AddReferals from "./Screens/AddReferals";
import Login from "./Screens/Login";
import Navbar from "./components/Navbar";
import AddReferalList from "./Screens/AddReferalList";
import AddDoctorsList from "./Screens/AddDoctorsList";
import EditPatient from "./Screens/EditPatient";
// import EditPatientDetails from "./Screens/EditPatient";
function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<PatientDetails />} />
        <Route path="/PatientInfo" element={<PatientInfo />} />
        <Route path="/PatientDetails" element={<PatientDetails />} />
        <Route path="/PatientDataDisplay" element={<PatientDataDisplay />} />
        <Route path="/AddDoctors" element={<AddDoctors />} />
        <Route path="/AddReferals" element={<AddReferals />} />
        <Route path="/login" element={<Login />} />
        <Route path="/patients/:doctorId" element={<AddReferalList />} />
        <Route path="/patients-doctor/:doctorName" element={<AddDoctorsList />} />
        {/* <Route path="/edit-patient/:id" element={<EditPatientDetails />} /> */}
        {/* <Route path="/edit/:id" element={<EditPatient />} /> */}
        <Route path="/edit/:id" element={<EditPatient />} />
      </Routes>
    </Router>
  );
}

export default App;
