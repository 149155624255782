import React, { useState } from "react";
// import "./LoginScree.css";
import { motion } from "framer-motion";
import "remixicon/fonts/remixicon.css";
import { useNavigate } from "react-router-dom";
import {BaseURl} from '../config/baseURL'

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false); // Loader ke liye state

  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!email) {
      formErrors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formErrors.email = "Email is invalid.";
      isValid = false;
    }

    if (!password) {
      formErrors.password = "Password is required.";
      isValid = false;
    } else if (password.length < 6) {
      formErrors.password = "Password must be at least 6 characters long.";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setIsLoading(true); // Loader ko true karna

      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);
      console.log("log123 --> ", `${BaseURl}login`);
      
      try {
        const response = await fetch(
          `${BaseURl}login`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          const data = await response.json();
          const { token } = data;
          localStorage.setItem("token", token);
          navigate("/PatientDetails");
        } else if (response.status === 401) {
          setErrors({ general: "Invalid email or password. Please try again." });
        } else {
          setErrors({ general: "Something went wrong. Please try again later." });
        }
      } catch (error) {
        setErrors({ general: "An error occurred while logging in." });
      } finally {
        setIsLoading(false); // Response milne ke baad loader false karna
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
        className="flex flex-col justify-center md:flex-row h-full bg-emerald-100 rounded-2xl"
      >
        {/* Left-side (Form) */}
        <div className="flex flex-col justify-center items-center p-8 md:w-1/2 bg-white shadow-md rounded-lg">
          <h1 className="text-3xl font-bold mb-4">Sign In</h1>

          <div className="flex space-x-4 mb-4 mt-4">
            <div className="social-container">
              <a
                href="#"
                className="social hover:bg-blue-800 hover:text-white border border-gray-500 rounded-full px-3 py-2 mx-4"
              >
                <i className="ri-facebook-circle-fill"></i>
              </a>
              <a
                href="#"
                className="social hover:bg-emerald-200 hover:text-white border border-gray-500 rounded-full px-3 py-2 mx-4"
              >
                <i className="ri-google-fill"></i>
              </a>
              <a
                href="#"
                className="social hover:bg-blue-800 hover:text-white border border-gray-500 rounded-full px-3 py-2 mx-4"
              >
                <i className="ri-twitter-fill"></i>
              </a>
            </div>
          </div>

          <p className="text-gray-600 mb-6 -mt-4 p-4 font-roboto">
            or use your account
          </p>

          <form
            onSubmit={handleSubmit}
            className="flex flex-col space-y-4 w-full"
          >
            <div className="relative">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                className={`font-roboto px-4 py-2 rounded-md border ${
                  errors.email ? "border-red-500" : "border-gray-300"
                } focus:outline-none focus:ring-2 focus:ring-red-500 w-full bg-gray-200`}
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>

            <div className="relative">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
                className={`px-4 py-2 rounded-md border ${
                  errors.password ? "border-red-500" : "border-gray-300"
                } focus:outline-none focus:ring-2 focus:ring-red-500 w-full bg-gray-200`}
              />
              {errors.password && (
                <p className="text-red-500 text-sm mt-1">{errors.password}</p>
              )}
            </div>

            {errors.general && (
              <p className="text-red-500 text-sm mt-2 text-center">
                {errors.general}
              </p>
            )}

            <div className="mx-auto">
              <button
                type="submit"
                className="bg-emerald-200 hover:bg-emerald-300 text-emerald-600 font-bold py-3 px-5 rounded-full w-full md:w-32 text-center"
                disabled={isLoading} // Disable button jab loading ho rahi ho
              >
                {isLoading ? ( // Agar loading ho rahi ho toh loader dikhana
                  <div className="flex items-center justify-center space-x-2">
                    <svg
                      className="animate-spin h-5 w-5 text-emerald-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8z"
                      ></path>
                    </svg>
                    <span>Signing In...</span>
                  </div>
                ) : (
                  "Sign In"
                )}
              </button>
            </div>
          </form>
        </div>

        <div className="flex flex-col justify-center items-center p-8 md:w-1/2 bg-emerald-200 shadow-md rounded-lg">
          <h1 className="text-3xl font-bold mb-4 text-emerald-600">
            Hello, Friend!
          </h1>
          <p className="text-emerald-600 mb-6 font-roboto text-lg text-center">
            Enter your personal details and start your journey with us.
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default Login;

// import React, { useState } from "react";
// // import "./LoginScree.css";
// import { motion } from "framer-motion";
// import "remixicon/fonts/remixicon.css";
// import { useNavigate } from "react-router-dom";

// const Login = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [errors, setErrors] = useState({});
//   const [isLoading, setIsLoading] = useState(false); // Loader ke liye state

//   const navigate = useNavigate();

//   const handleSignUpClick = () => {
//     navigate("/signup");
//   };

//   const handleEmailChange = (event) => {
//     setEmail(event.target.value);
//   };

//   const handlePasswordChange = (event) => {
//     setPassword(event.target.value);
//   };

//   const validateForm = () => {
//     let formErrors = {};
//     let isValid = true;

//     if (!email) {
//       formErrors.email = "Email is required.";
//       isValid = false;
//     } else if (!/\S+@\S+\.\S+/.test(email)) {
//       formErrors.email = "Email is invalid.";
//       isValid = false;
//     }

//     if (!password) {
//       formErrors.password = "Password is required.";
//       isValid = false;
//     } else if (password.length < 6) {
//       formErrors.password = "Password must be at least 6 characters long.";
//       isValid = false;
//     }

//     setErrors(formErrors);
//     return isValid;
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (validateForm()) {
//       setIsLoading(true); // Loader ko true karna

//       const formData = new FormData();
//       formData.append("email", email);
//       formData.append("password", password);

//       try {
//         const response = await fetch(
//           "https://saas.lifesecurepathology.com/api/login",
//           {
//             method: "POST",
//             body: formData,
//           }
//         );

//         if (response.ok) {
//           const data = await response.json();
//           const { token } = data;
//           localStorage.setItem("token", token);
//           navigate("/PatientDetails");
//         } else if (response.status === 401) {
//           setErrors({ general: "Invalid email or password. Please try again." });
//         } else {
//           setErrors({ general: "Something went wrong. Please try again later." });
//         }
//       } catch (error) {
//         setErrors({ general: "An error occurred while logging in." });
//       } finally {
//         setIsLoading(false); // Response milne ke baad loader false karna
//       }
//     }
//   };

//   return (
//     <div className="flex items-center justify-center min-h-screen">
//       <motion.div
//         initial={{ opacity: 0, y: 100 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 0.6, ease: "easeOut" }}
//         className="flex flex-col justify-center md:flex-row h-full bg-emerald-100 rounded-2xl"
//       >
//         {/* Left-side (Form) */}
//         <div className="flex flex-col justify-center items-center p-8 md:w-1/2 bg-white shadow-md rounded-lg">
//           <h1 className="text-3xl font-bold mb-4">Sign In</h1>

//           <div className="flex space-x-4 mb-4 mt-4">
//             <div className="social-container">
//               <a
//                 href="#"
//                 className="social hover:bg-blue-800 hover:text-white border border-gray-500 rounded-full px-3 py-2 mx-4"
//               >
//                 <i className="ri-facebook-circle-fill"></i>
//               </a>
//               <a
//                 href="#"
//                 className="social hover:bg-emerald-200 hover:text-white border border-gray-500 rounded-full px-3 py-2 mx-4"
//               >
//                 <i className="ri-google-fill"></i>
//               </a>
//               <a
//                 href="#"
//                 className="social hover:bg-blue-800 hover:text-white border border-gray-500 rounded-full px-3 py-2 mx-4"
//               >
//                 <i className="ri-twitter-fill"></i>
//               </a>
//             </div>
//           </div>

//           <p className="text-gray-600 mb-6 -mt-4 p-4 font-roboto">
//             or use your account
//           </p>

//           <form
//             onSubmit={handleSubmit}
//             className="flex flex-col space-y-4 w-full"
//           >
//             <div className="relative">
//               <input
//                 type="email"
//                 placeholder="Email"
//                 value={email}
//                 onChange={handleEmailChange}
//                 className={`font-roboto px-4 py-2 rounded-md border ${
//                   errors.email ? "border-red-500" : "border-gray-300"
//                 } focus:outline-none focus:ring-2 focus:ring-red-500 w-full bg-gray-200`}
//               />
//               {errors.email && (
//                 <p className="text-red-500 text-sm mt-1">{errors.email}</p>
//               )}
//             </div>

//             <div className="relative">
//               <input
//                 type="password"
//                 placeholder="Password"
//                 value={password}
//                 onChange={handlePasswordChange}
//                 className={`px-4 py-2 rounded-md border ${
//                   errors.password ? "border-red-500" : "border-gray-300"
//                 } focus:outline-none focus:ring-2 focus:ring-red-500 w-full bg-gray-200`}
//               />
//               {errors.password && (
//                 <p className="text-red-500 text-sm mt-1">{errors.password}</p>
//               )}
//             </div>

//             {errors.general && (
//               <p className="text-red-500 text-sm mt-2 text-center">
//                 {errors.general}
//               </p>
//             )}

//             <div className="mx-auto">
//               <button
//                 type="submit"
//                 className="bg-emerald-200 hover:bg-emerald-300 text-emerald-600 font-bold py-3 px-5 rounded-full w-full md:w-32 text-center"
//                 disabled={isLoading} // Disable button jab loading ho rahi ho
//               >
//                 {isLoading ? ( // Agar loading ho rahi ho toh loader dikhana
//                   <div className="flex items-center justify-center space-x-2">
//                     <svg
//                       className="animate-spin h-5 w-5 text-emerald-600"
//                       xmlns="http://www.w3.org/2000/svg"
//                       fill="none"
//                       viewBox="0 0 24 24"
//                     >
//                       <circle
//                         className="opacity-25"
//                         cx="12"
//                         cy="12"
//                         r="10"
//                         stroke="currentColor"
//                         strokeWidth="4"
//                       ></circle>
//                       <path
//                         className="opacity-75"
//                         fill="currentColor"
//                         d="M4 12a8 8 0 018-8v8z"
//                       ></path>
//                     </svg>
//                     <span>Signing In...</span>
//                   </div>
//                 ) : (
//                   "Sign In"
//                 )}
//               </button>
//             </div>

//             <div className="flex mx-auto justify-center">
//               <a href="#" className="text-gray-600 hover:text-emerald-300 font-roboto">
//                 Forgot your password?
//               </a>
//             </div>
//           </form>
//         </div>

//         <div className="flex flex-col justify-center items-center p-8 md:w-1/2 bg-emerald-200 shadow-md rounded-lg">
//           <h1 className="text-3xl font-bold mb-4 text-emerald-600">
//             Hello, Friend!
//           </h1>
//           <p className="text-emerald-600 mb-6 font-roboto text-lg text-center">
//             Enter your personal details and start your journey with us.
//           </p>
//           <button
//             onClick={handleSignUpClick}
//             className="bg-white hover:bg-gray-100 text-emerald-600 font-bold py-3 px-4 rounded-lg w-full md:w-32"
//           >
//             Sign Up
//           </button>
//         </div>
//       </motion.div>
//     </div>
//   );
// };

// export default Login;
