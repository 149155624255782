
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BaseURl } from '../config/baseURL';

const EditPatient = () => {
  const { id } = useParams(); // Get the patient ID from URL
  const [patientData, setPatientData] = useState({
    name: "",
    age: "",
    dr_name: "",
    ref_by: "",
    test: "",
    amount: "",
    amount_paid: "",
    amount_paid_online: "",
    amount_paid_cash: "",
    amount_due: "",
    rcless: "",
  });

  const [loading, setLoading] = useState(true); // Loading state to show loading while fetching
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const [errorMessage, setErrorMessage] = useState(""); // Error message if any field is empty
  const [showPreview, setShowPreview] = useState(false); // To toggle the preview mode

  const navigate = useNavigate(); // Initialize useNavigate hook

  // Fetch patient data by ID
  useEffect(() => {
    const fetchPatientDetails = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(`${BaseURl}patients/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        setPatientData(data); // Set patient data in state
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Failed to fetch patient data:", error);
        setLoading(false); // Stop loading in case of error
      }
    };

    fetchPatientDetails();
  }, [id]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPatientData((prevData) => ({
      ...prevData,
      [name]: value, // Dynamically update the respective field
    }));
  };

  // Save the edited patient data to the server
  const handleSave = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${BaseURl}patients/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(patientData), // Send updated data
      });
      const result = await response.json();
      console.log("Saved data:", result);

      // Show success message after saving
      setSuccessMessage('Patient data saved successfully!');
      
      // Navigate back to the main page or patient list after saving
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (error) {
      console.error("Error saving patient data:", error);
    }
  };

  // Preview the patient data before saving
  const handlePreview = () => {
    if (Object.values(patientData).some((value) => value.trim() === "")) {
      setErrorMessage("All fields are required!");
      return;
    }
    setErrorMessage("");
    setShowPreview(true);
  };

  if (loading) return <p>Loading...</p>; // Show loading if data is not yet fetched

  return (
    <div className="flex items-start justify-center min-h-screen bg-gradient-to-r from-green-200 to-green-100 relative">
      <div className="relative bg-white bg-opacity-80 p-6 rounded-xl shadow-2xl w-full max-w-md mt-8">
        <h2 className="text-3xl font-extrabold mb-4 text-center text-transparent bg-clip-text bg-gradient-to-r from-green-300 to-emerald-600">
          Edit Patient
        </h2>

        {/* Form Inputs */}
        {[{
          label: "Patient Name", name: "name", type: "text", placeholder: "Enter Patient Name",
        },
        {
          label: "Age", name: "age", type: "number", placeholder: "Enter Age",
        },
        {
          label: "Doctor Name", name: "dr_name", type: "text", placeholder: "Enter Doctor Name",
        },
        {
          label: "Referred By", name: "ref_by", type: "text", placeholder: "Enter Referred By",
        },
        {
          label: "Tests", name: "test", type: "text", placeholder: "Enter Tests",
        },
        {
          label: "Amount Total", name: "amount", type: "number", placeholder: "Enter Amount Total",
        },
        {
          label: "Amount Paid", name: "amount_paid", type: "number", placeholder: "Enter Amount Paid",
        },
        {
          label: "Amount Online", name: "amount_paid_online", type: "number", placeholder: "Enter Amount Online",
        },
        {
          label: "Amount Cash", name: "amount_paid_cash", type: "number", placeholder: "Enter Amount Cash",
        },
        {
          label: "Amount Due", name: "amount_due", type: "number", placeholder: "Enter Amount Due",
        },
        {
          label: "RC Less", name: "rcless", type: "number", placeholder: "Enter RC Less",
        }].map(({ label, name, type, placeholder }, index) => (
          <div key={index} className="mb-2 flex items-center">
            <label className="block text-gray-700 font-semibold w-1/3">
              {label}:
            </label>
            <input
              type={type}
              name={name}
              placeholder={placeholder}
              value={patientData[name]} // Bind the field with patientData
              onChange={handleInputChange} // Call handleInputChange on change
              className="w-2/3 border-2 border-gray-300 p-2 rounded focus:ring-2 focus:ring-teal-400 transition-all duration-300"
            />
          </div>
        ))}

        {errorMessage && (
          <p className="text-red-500 text-center mb-3 animate-bounce">
            {errorMessage}
          </p>
        )}

        {/* Preview and Save Buttons */}
        <div className="flex justify-between mt-4">
          <button
            type="button"
            onClick={handlePreview}
            className="bg-gradient-to-r from-blue-600 to-teal-400 text-white py-2 px-4 rounded-lg text-lg font-bold hover:shadow-lg transition-transform transform hover:scale-105 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Preview
          </button>

          <button
            type="button"
            onClick={handleSave}
            className="bg-gradient-to-r from-green-600 to-teal-400 text-white py-2 px-4 rounded-lg text-lg font-bold hover:shadow-lg transition-transform transform hover:scale-105 focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Save
          </button>
        </div>

        {/* Success message */}
        {successMessage && (
          <div className="text-green-600 text-center mt-4">
            {successMessage}
          </div>
        )}

        {/* Preview Modal */}
        {showPreview && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-xl shadow-2xl w-80">
              <h3 className="text-lg font-bold mb-4 text-center">Patient Information Preview</h3>
              <ul className="text-sm">
                {Object.entries(patientData).map(([key, value], index) => (
                  <li key={index} className="mb-1">
                    <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}
                  </li>
                ))}
              </ul>
              <div className="flex justify-between mt-4">
                <button
                  onClick={() => setShowPreview(false)}
                  className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition-colors"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditPatient;
