import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo-image.jpg";
const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleNavigate = (path) => {
    navigate(path);
    setIsOpen(false); // Close the menu when a link is clicked (for mobile)
  };
  
  return (
    <nav className="bg-white shadow-md ">
      <div className="container mx-auto flex justify-between  ">
        {/* Left side - Logo */}
        <div className="text-4xl font-bold text-emerald-500 flex-shrink-0 ">
          <div className="flex">
            <img className="w-12" src={logo}></img>
            <span className="text-6xl  text-emerald-300">D</span>{" "}
            <span className="pt-4">octor</span>
          </div>
        </div>

        {/* Right side - Menu for larger screens */}
        <ul className="hidden md:flex space-x-6 pt-4">
          <li>
            <button
              className="text-emerald-500 font-semibold hover:text-emerald-300"
              onClick={() => handleNavigate("/PatientInfo")}
            >
              Patient Info
            </button>
          </li>
          <li>
            <button
              className="text-emerald-500 font-semibold hover:text-emerald-300"
              onClick={() => handleNavigate("/PatientDetails")}
            >
              Patient Details
            </button>
          </li>
          <li>
            <button
              className="text-emerald-500 font-semibold hover:text-emerald-300"
              onClick={() => handleNavigate("/AddDoctors")}
            >
              Add Doctors
            </button>
          </li>
          <li>
            <button
              className="text-emerald-500 font-semibold hover:text-emerald-300"
              onClick={() => handleNavigate("/AddReferals")}
            >
              Add Referrals
            </button>
          </li>
          <li>
            <button
              className="text-emerald-500 font-semibold hover:text-emerald-300"
              onClick={() => handleNavigate("/login")}
            >
              Login
            </button>
          </li>
          {/* <li>
            <button
              className="bg-emerald-500 text-white relative -top-2 px-5 py-2 rounded hover:bg-emerald-400"
              onClick={() => handleNavigate("/signup")}
            >
              Sign Up
            </button>
          </li> */}
        </ul>

        {/* Hamburger Menu for mobile devices */}
        <div className="md:hidden mt-4 px-2">
          <button
            className="text-emerald-500 text-2xl"
            onClick={() => setIsOpen(!isOpen)}
          >
            &#9776; {/* Hamburger icon */}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <ul className="flex flex-col text-center space-y-4 md:hidden mx-auto mt-4">
          <li>
            <button
              className="text-emerald-500 font-semibold"
              onClick={() => handleNavigate("/PatientInfo")}
            >
              Patient Info
            </button>
          </li>
          <li>
            <button
              className="text-emerald-500 font-semibold"
              onClick={() => handleNavigate("/PatientDetails")}
            >
              Patient Details
            </button>
          </li>
          <li>
            <button
              className="text-emerald-500 font-semibold"
              onClick={() => handleNavigate("/AddDoctors")}
            >
              Add Doctors
            </button>
          </li>
          <li>
            <button
              className="text-emerald-500 font-semibold"
              onClick={() => handleNavigate("/AddReferals")}
            >
              Add Referrals
            </button>
          </li>
          <li>
            <button
              className="text-emerald-500 font-semibold"
              onClick={() => handleNavigate("/login")}
            >
              Login
            </button>
          </li>
          {/* <li className="">
            <button
              className=" text-emerald-500  font-semibold text-md  rounded "
              onClick={() => handleNavigate("/signup")}
            >
              Sign Up
            </button>
          </li> */}
        </ul>
      )}
    </nav>
  );
};

export default Navbar;
