
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom"; // useNavigate का इंपोर्ट करें
import { BaseURl } from "../config/baseURL";

const AddReferal = () => {
  const [tasks, setTasks] = useState([]);
  const [taskInput, setTaskInput] = useState("");
  const navigate = useNavigate(); // useNavigate हुक का उपयोग करें

  useEffect(() => {
    getRefers();
  }, []);

  const addTask = () => {
    if (taskInput.trim()) {
      addReferAPI(taskInput);
      setTaskInput("");
    }
  };

  const addReferAPI = async (taskInput) => {
    const formData = new FormData();
    formData.append("name", taskInput);
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${BaseURl}users`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        getRefers();
      } else {
        console.error("Login failed: ", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getRefers = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${BaseURl}users`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setTasks(data.data);
      } else {
        console.error("Login failed: ", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleReferClick = (taskName) => {
    navigate(`/patients/${taskName}`); // नया पेज खोलेगा जिसमें मरीजों की जानकारी होगी
  };

  return (
    <div className="bg-emerald-100  pt-20 pb-10 min-h-screen">
      <motion.div
        className="p-6 max-w-lg lg:max-w-2xl mx-auto bg-emerald-100 rounded-lg shadow-md"
        initial="hidden"
        animate="visible"
      >
        <h1 className="relative text-center text-2xl sm:text-3xl lg:text-4xl font-bold mb-4 text-gray-800">
          <span className="absolute inset-0 bg-gradient-to-r from-emerald-300 to-emerald-400 opacity-30 blur-md -z-10"></span>
          <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500">
            Add Referral
          </span>
        </h1>
        <div className="flex flex-col sm:flex-row mb-4">
          <input
            type="text"
            value={taskInput}
            onChange={(e) => setTaskInput(e.target.value)}
            className="flex-1 border border-gray-300 rounded-t-lg sm:rounded-l-lg sm:rounded-t-none py-2 px-4 mb-4 sm:mb-0"
            placeholder="Add a new task..."
          />
          <button
            onClick={addTask}
            className="bg-emerald-200 text-white rounded-b-lg sm:rounded-r-lg sm:rounded-b-none px-4 py-2 hover:bg-blue-600 transition-colors"
          >
            Add
          </button>
        </div>
        <ul className="list-disc pl-5 space-y-2">
          {tasks.map((task) => (
            <li
              key={task.id}
              className={`flex items-center justify-between mb-2 py-2 px-4 rounded-lg border ${
                task.completed ? "bg-green-100" : "bg-gray-100"
              } transition-colors`}
              onClick={() => handleReferClick(task.id)} // रेफर पर क्लिक करने पर फ़ंक्शन कॉल करें
            >
              <span
                className={`flex-1 cursor-pointer ${
                  task.completed
                    ? "line-through text-gray-500"
                    : "text-gray-800"
                }`}
              >
                {task.name}
              </span>
            </li>
          ))}
        </ul>
      </motion.div>
    </div>
  );
};

export default AddReferal;
