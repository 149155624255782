
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BaseURl } from "../config/baseURL";

const AddDoctorsList = () => {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const doctorData = useLocation();

  useEffect(() => {
    fetchPatients();
  }, [doctorData]);

  const fetchPatients = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${BaseURl}patients?doctor_id=${doctorData.state.doctorName.id}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.ok) {
        const data = await response.json();
        setPatients(data.data); // JSON से डेटा सेट करें
      } else {
        console.error("Error fetching patients:", response.statusText);
        setError("Failed to fetch data from the server. Please try again later.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setError("Something went wrong. Please check your connection and try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="text-center py-4">Loading...</div>;
  }

  if (error) {
    return (
      <div className="bg-red-100 text-red-700 text-center py-4">
        {error}
      </div>
    );
  }

  return (
    <div className="bg-emerald-100 h-auto pt-20 pb-10">
      <h1 className="text-center text-3xl font-bold mb-4">Doctor: {doctorData.state.doctorName.name}</h1> {/* यहाँ पर doctorName को दिखा रहे हैं */}
      <div className="max-w-4xl mx-auto">
        <table className="table-auto w-full border-collapse border border-gray-400">
          <thead>
            <tr className="bg-gray-200">
              <th className="border px-4 py-2">Patient Name</th>
              <th className="border px-4 py-2">Test</th>
              <th className="border px-4 py-2">Total Price</th>
              <th className="border px-4 py-2">RC Price</th>
            </tr>
          </thead>
          <tbody>
            {patients.length > 0 ? (
              patients.map((patient) => (
                <tr key={patient.id}>
                  <td className="border px-4 py-2">{patient.name}</td>
                  <td className="border px-4 py-2">{patient.test}</td>
                  <td className="border px-4 py-2">{patient.amount}</td>
                  <td className="border px-4 py-2">{patient.rcless}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center py-4">
                  No patients found for this doctor
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddDoctorsList;
