
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BaseURl } from "../config/baseURL";

const AddReferalList = () => {
  const { doctorId, doctorName } = useParams();
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    fetchDoctors();
  }, [doctorId, selectedMonth, selectedYear]);

  const fetchDoctors = async () => {
    const token = localStorage.getItem("token");
    console.log("res123 endpoint --> ", `${BaseURl}patients?ref_by_id=${doctorId}`);
    
    try {
      const response = await fetch(
        `${BaseURl}patients?ref_by_id=${doctorId}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("res123 res --> ", response);

      if (response.ok) {
        const data = await response.json();
        const filteredDoctors = data.data.filter(doctor => {
          const doctorDate = new Date(doctor.created_at);
          return (
            doctorDate.getMonth() === selectedMonth &&
            doctorDate.getFullYear() === selectedYear
          );
        });

        setDoctors(filteredDoctors);
      } else {
        setError("Failed to fetch data from the server. Please try again later.");
      }
    } catch (error) {
      setError("Something went wrong. Please check your connection and try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div className="text-center text-lg font-semibold py-4">Loading...</div>;

  if (error) {
    return (
      <div className="bg-red-100 text-red-700 text-center py-4 rounded-lg shadow-lg">
        {error}
      </div>
    );
  }

  const handleMonthChange = (e) => {
    setSelectedMonth(parseInt(e.target.value));
  };

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value));
  };

  return (
    <div className="bg-gradient-to-r from-green-200 via-emerald-200 to-teal-200 h-auto py-10">
      <h1 className="text-center text-4xl font-bold text-teal-800 mb-8">
        Referrals for Dr. {doctorId}
      </h1>

      {/* Month and Year Selector */}
      <div className="flex justify-center items-center mb-6">
        <select
          value={selectedMonth}
          onChange={handleMonthChange}
          className="px-4 py-2 border border-gray-300 rounded-lg shadow-sm mr-4 focus:outline-none focus:ring-2 focus:ring-emerald-500"
        >
          {Array.from({ length: 12 }, (_, index) => (
            <option key={index} value={index}>
              {new Date(0, index).toLocaleString("default", { month: "long" })}
            </option>
          ))}
        </select>

        <select
          value={selectedYear}
          onChange={handleYearChange}
          className="px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500"
        >
          <option value={2024}>2024</option>
          <option value={2023}>2023</option>
        </select>
      </div>

      <div className="max-w-5xl mx-auto px-4">
        <table className="table-auto w-full border-collapse shadow-lg">
          <thead>
            <tr className="bg-teal-600 text-white text-lg font-semibold">
              <th className="border px-6 py-3">Patient Name</th>
              <th className="border px-6 py-3">Test</th>
              <th className="border px-6 py-3">Total Price</th>
              <th className="border px-6 py-3">RC Price (Commission)</th>
            </tr>
          </thead>
          <tbody>
            {doctors.length > 0 ? (
              doctors.map((doctor) => (
                <tr key={doctor.id} className="bg-white even:bg-gray-100">
                  <td className="border px-6 py-3 text-center">{doctor.name || "N/A"}</td>
                  <td className="border px-6 py-3 text-center">{doctor.test || "N/A"}</td>
                  <td className="border px-6 py-3 text-center">{doctor.amount || "N/A"}</td>
                  <td className="border px-6 py-3 text-center">{doctor.rcless || "N/A"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center py-6" colSpan="4">
                  No referrals found for this doctor in the selected month.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddReferalList;
