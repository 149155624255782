import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import { useNavigate } from "react-router-dom";
import { BaseURl } from "../config/baseURL";

export default function PatientForm() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: localStorage.getItem('name') || '',
    age: localStorage.getItem('age') || '',
    doctor_id: { id: '', name: localStorage.getItem('doctor_name') || '' },
    ref_by_id: { id: '', name: localStorage.getItem('ref_by_name') || '' },
    test: localStorage.getItem('test') || '',
    amount: localStorage.getItem('amount') || '',
    amount_paid: localStorage.getItem('amount_paid') || '',
    amount_paid_online: localStorage.getItem('amount_paid_online') || '',
    amount_paid_cash: localStorage.getItem('amount_paid_cash') || '',
    amount_due: localStorage.getItem('amount_due') || '',
    rcless: localStorage.getItem('rcless') || '',
  });  

  const [errorMessage, setErrorMessage] = useState("");
  const [showPreview, setShowPreview] = useState(false);

  const [doctorSuggestions, setDoctorSuggestions] = useState([]);
  const [filteredDoctorSuggestions, setDoctorFilteredSuggestions] = useState([]);
  
  const [referSuggestions, setReferSuggestions] = useState([]);
  const [filteredReferSuggestions, setFilteredReferdSuggestions] = useState([]);
  
  const [showSuggestionsDoctors, setShowSuggestionsDoctors] = useState(false);
  const [showSuggestionsRefers, setShowSuggestionsRefers] = useState(false);

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const [referalSuggestions, setReferalSuggestions] = useState([]);
  const [formLocalData, setFormLocalData] = useState('');
  
  const [currentPage, setCurrentPage] = useState(1);
  const suggestionsPerPage = 5;

  useEffect(() => {
    const fetchDoctors = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          `${BaseURl}doctors`,
          {
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
          }
        ); 
        const doctors = await response.json();
        
        setDoctorSuggestions(doctors.data);
      } catch (error) {
        console.error("Failed to fetch doctors:", error);
      }
    };

    fetchDoctors();
  }, []);

  useEffect(() => {
    const fetchReferals = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${BaseURl}users`,
          {
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const refers = await response.json();
        setReferSuggestions(refers.data);
      } catch (error) {
        console.error("Failed to fetch refers:", error);
      }
    };

    fetchReferals();
  }, []);

  const handleInputChange = (e) => {
    // setFormLocalData(e.target.value)
    // localStorage.setItem(`${e.target.name}`, e.target.value)

    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    localStorage.setItem(name, value);

    if (name === "doctor_id" && value.length > 0) {
      const filteredDoctor = doctorSuggestions.filter((doctor) => {        
        return doctor.name.toLowerCase().includes(value.toLowerCase());
      });
      setShowSuggestionsDoctors(true);
      setDoctorFilteredSuggestions(filteredDoctor);
    } else if (name === "ref_by_id" && value.length > 0) {
      const filteredRefer = referSuggestions.filter((refers) => {
        return refers.name.toLowerCase().includes(value.toLowerCase());
      });

      setShowSuggestionsRefers(true);
      setFilteredReferdSuggestions(filteredRefer);
    } else {
      setShowSuggestionsRefers(false);
      setShowSuggestionsDoctors(false);
    }
  };

  const handleDoctorSelect = (doctor) => {    
    setFormData((prevData) => ({ ...prevData, doctor_id: {name: doctor.name, id: doctor.id} }));
    setShowSuggestionsRefers(false);
    setShowSuggestionsDoctors(false);
  };

  const handleReferSelect = (refer) => {
    setFormData((prevData) => ({ ...prevData, ref_by_id: {name: refer.name, id: refer.id} }));
    setShowSuggestionsRefers(false);
    setShowSuggestionsDoctors(false);
  };

  const handlePreview = () => {
    if (
      Object.values(formData).some((value) => {
        if (typeof value === "object" && value !== null) {
          return Object.values(value).some(
            (subValue) => typeof subValue === "string" && subValue.trim() === ""
          );
        }
        return typeof value === "string" && value.trim() === "";
      })
    ) {
      setErrorMessage("All fields are required to fill!");
      return;
    }
    setErrorMessage("");
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const deleteMultipleItemsFromLocalStorage = (keys) => {
    keys.forEach((key) => {
      localStorage.removeItem(key);
    });
  };

  const generatePDF = async () => {

    const formApiData = new FormData();

    Object.entries(formData).forEach(([key, value], index) => {
      
      if(key === "doctor_id" || key === "ref_by_id"){
        formApiData.append(key, value.id);
      }else{
        formApiData.append(key, value);
      }
    });
    try {
      const token = localStorage.getItem("token");

      const response = await fetch(
        `${BaseURl}patients`,
        {
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
          body: formApiData,
        }
      );

      if (response.ok) {
        const data = await response.json();        
        setShowSuccessMsg(true);

              
const keysToDelete = ["name", "age", "doctor_name","ref_by_name", "age", "test","amount", "amount_paid", "amount_paid_online", "amount_paid_cash", "amount_due","rcless",];
deleteMultipleItemsFromLocalStorage(keysToDelete);

      } else {
        console.error("Login failed: ", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    
    setShowPreview(false);
  };

  // Pagination Logic
  const paginate = (array, page_number) => {
    return array.slice((page_number - 1) * suggestionsPerPage, page_number * suggestionsPerPage);
  };

  const returnData = (id) => {
    
    if(id == "doctor_id" || id == "ref_by_id"){
    return formData[id].name;
   }
   return formData[id];
  }
  return (
    <div className="flex items-start justify-center min-h-screen bg-gradient-to-r from-green-200 to-green-100 relative">
      <div className="absolute inset-0 bg-cover bg-center opacity-40"></div>

      <div className="relative bg-white bg-opacity-80 p-6 rounded-xl shadow-2xl w-full max-w-md mt-8">
        <h2 className="text-3xl font-extrabold mb-4 text-center text-transparent bg-clip-text bg-gradient-to-r from-green-300 to-emerald-600">
          Patient Information
        </h2>

        {/* Input Fields */}
        {[
          { label: "Patient Name", name: "name", type: "text", placeholder: "Enter Patient Name" },
          { label: "Age", name: "age", type: "number", placeholder: "Enter Age" },
          { label: "Doctor Name", name: "doctor_id", type: "text", placeholder: "Enter Doctor Name" },
          { label: "Referred By", name: "ref_by_id", type: "text", placeholder: "Enter Referred By" },
          { label: "Tests", name: "test", type: "text", placeholder: "Enter Tests" },
          { label: "Amount Total", name: "amount", type: "number", placeholder: "Enter Amount Total" },
          { label: "Amount Paid", name: "amount_paid", type: "number", placeholder: "Enter Amount Paid" },
          { label: "Amount Online", name: "amount_paid_online", type: "number", placeholder: "Enter Amount Online" },
          { label: "Amount Cash", name: "amount_paid_cash", type: "number", placeholder: "Enter Amount Cash" },
          { label: "Amount Due", name: "amount_due", type: "number", placeholder: "Enter Amount Due" },
          { label: "RC Less", name: "rcless", type: "number", placeholder: "Enter RC Less" },
        ].map(({ label, name, type, placeholder }, index) => (
          <div key={index} className="mb-2 flex items-center">
            <label className="block text-gray-700 font-semibold w-1/3">
              {label}:
            </label>
            <input
              type={type}
              name={name}
              placeholder={placeholder}
              value={returnData(name)}
              onChange={handleInputChange}
              className="w-2/3 border-2 border-gray-300 p-2 rounded focus:ring-2 focus:ring-teal-400 transition-all duration-300"
            />
            
            {name === "doctor_id" && showSuggestionsDoctors && filteredDoctorSuggestions.length > 0 && (
              <ul className="absolute bg-white border border-gray-300 w-2/3 mt-2 rounded-lg shadow-md">
                {paginate(filteredDoctorSuggestions, currentPage).map((doctor, idx) => (
                  <li
                    key={idx}
                    onClick={() => handleDoctorSelect(doctor)}
                    className="p-2 hover:bg-teal-400 hover:text-white cursor-pointer"
                  >
                    {doctor.name}
                  </li>
                ))}
                <div className="flex justify-between mt-2">
                  {currentPage > 1 && (
                    <button
                      onClick={() => setCurrentPage(currentPage - 1)}
                      className="text-sm text-teal-600 hover:underline"
                    >
                      Previous
                    </button>
                  )}
                  {filteredDoctorSuggestions.length > currentPage * suggestionsPerPage && (
                    <button
                      onClick={() => setCurrentPage(currentPage + 1)}
                      className="text-sm text-teal-600 hover:underline"
                    >
                      Next
                    </button>
                  )}
                </div>
              </ul>
            )}

            {name === "ref_by_id" && showSuggestionsRefers && filteredReferSuggestions.length > 0 && (
              <ul className="absolute bg-white border border-gray-300 w-2/3 mt-2 rounded-lg shadow-md">
                {paginate(filteredReferSuggestions, currentPage).map((refer, idx) => (
                  <li
                    key={idx}
                    onClick={() => handleReferSelect(refer)}
                    className="p-2 hover:bg-teal-400 hover:text-white cursor-pointer"
                  >
                    {refer.name}
                  </li>
                ))}
                <div className="flex justify-between mt-2">
                  {currentPage > 1 && (
                    <button
                      onClick={() => setCurrentPage(currentPage - 1)}
                      className="text-sm text-teal-600 hover:underline"
                    >
                      Previous
                    </button>
                  )}
                  {filteredReferSuggestions.length > currentPage * suggestionsPerPage && (
                    <button
                      onClick={() => setCurrentPage(currentPage + 1)}
                      className="text-sm text-teal-600 hover:underline"
                    >
                      Next
                    </button>
                  )}
                </div>
              </ul>
            )}
          </div>
        ))}

        {errorMessage && (
          <p className="text-red-500 text-sm font-bold mb-4">{errorMessage}</p>
        )}

        <div className="flex justify-center mt-4">
          <button
            onClick={handlePreview}
            className="bg-gradient-to-r from-teal-400 to-teal-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-gradient-to-l hover:shadow-lg transform hover:scale-105 transition-transform duration-300"
          >
            Preview & Submit
          </button>
        </div>

        {showPreview && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-lg font-bold mb-4">Preview Patient Details</h3>
              <ul className="list-disc list-inside">
                <li>Patient Name: {formData.name}</li>
                <li>Age: {formData.age}</li>
                <li>Doctor Name: {formData.doctor_id.name}</li>
                <li>Referred By: {formData.ref_by_id.name}</li>
                <li>Tests: {formData.test}</li>
                <li>Amount: {formData.amount}</li>
                <li>Amount Paid: {formData.amount_paid}</li>
                <li>Amount Paid (Online): {formData.amount_paid_online}</li>
                <li>Amount Paid (Cash): {formData.amount_paid_cash}</li>
                <li>Amount Due: {formData.amount_due}</li>
                <li>RC Less: {formData.rcless}</li>
              </ul>
              <div className="mt-4 flex justify-center">
                <button
                  onClick={generatePDF}
                  className="bg-teal-600 text-white py-2 px-4 rounded-lg font-bold"
                >
                  Submit
                </button>
                <button
                  onClick={handleClosePreview}
                  className="ml-4 bg-red-500 text-white py-2 px-4 rounded-lg font-bold"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {showSuccessMsg && (
        <div className="fixed bottom-0 right-0 p-4 bg-green-500 text-white rounded-lg shadow-lg">
          Patient record added successfully!
        </div>
      )}
    </div>
  );
}
