
// import React, { useState, useEffect } from "react";
// import { useNavigate } from 'react-router-dom';

// const PatientDataDisplay = () => {
//   const [showDetails, setShowDetails] = useState({});
//   const [patientDetails, setPatientDetails] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [noDataMessage, setNoDataMessage] = useState(false);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [selectedTest, setSelectedTest] = useState("");
//   const [availableTests, setAvailableTests] = useState([]);
//   const [selectedDate, setSelectedDate] = useState(() => {
//     const today = new Date();
//     return today.toISOString().split("T")[0];
//   });

//   const navigate = useNavigate(); // Initialize navigate for routing

//   const toggleDetails = (id) => {
//     setShowDetails((prev) => ({ ...prev, [id]: !prev[id] }));
//   };

//   const fetchPatientDetails = async () => {
//     const token = localStorage.getItem("token");
//     setLoading(true);
//     try {
//       const response = await fetch(
//         `https://saas.lifesecurepathology.com/api/patients?date=${selectedDate}`,
//         {
//           headers: { Authorization: `Bearer ${token}` },
//           method: "GET",
//         }
//       );
//       const data = await response.json();

//       if (Array.isArray(data.data) && data.data.length > 0) {
//         setPatientDetails(data.data.reverse());
//         setNoDataMessage(false);

//         // Populate available tests
//         const tests = data.data.reduce((acc, item) => {
//           if (item.test && !acc.includes(item.test)) {
//             acc.push(item.test);
//           }
//           return acc;
//         }, []);
//         setAvailableTests(tests);
//       } else {
//         setPatientDetails([]);
//         setNoDataMessage(true);
//       }
//     } catch (error) {
//       console.error("Failed to fetch patient data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (selectedDate) {
//       fetchPatientDetails();
//     }
//   }, [selectedDate]);

//   const filteredPatients = patientDetails.filter((item) => {
//     const matchesName = item.name.toLowerCase().includes(searchTerm.toLowerCase());
//     const matchesTest = selectedTest === "" || item.test.toLowerCase().includes(selectedTest.toLowerCase());
//     return matchesName && matchesTest;
//   });

//   // Function to handle editing
//   const handleEdit = (item) => {
//     navigate(`/edit/${item.id}`, { state: { patient: item } }); // Navigate to edit page with patient data
//   };

//   return (
//     <div className="w-full mx-auto m-5 p-5 font-sans bg-gray-100 rounded-lg shadow-md">
//       <h1 className="text-center text-gray-800 text-3xl font-bold mb-4">
//         Patient Test Results
//       </h1>

//       {/* Filter Section */}
//       <div className="mb-4 flex flex-wrap justify-between items-center space-y-4 md:space-y-0">
//         {/* Search by Name */}
//         <input
//           type="text"
//           placeholder="Search by Name"
//           className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//         />

//         {/* Filter by Test */}
//         <select
//           className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-48"
//           value={selectedTest}
//           onChange={(e) => setSelectedTest(e.target.value)}
//         >
//           <option value="">Filter by Test</option>
//           {availableTests.map((test, index) => (
//             <option key={index} value={test}>
//               {test}
//             </option>
//           ))}
//         </select>

//         {/* Filter by Date */}
//         <input
//           type="date"
//           value={selectedDate}
//           onChange={(e) => setSelectedDate(e.target.value)}
//           className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//         />
//       </div>

//       {/* Table Section */}
//       <div className="bg-white shadow-lg rounded-lg overflow-hidden">
//         <div className="overflow-x-auto">
//           {noDataMessage ? (
//             <p className="text-center text-red-500 font-semibold p-4">
//               No data available for the selected date.
//             </p>
//           ) : loading ? (
//             <p className="text-center text-gray-500">Loading...</p>
//           ) : (
//             <table className="min-w-full divide-y divide-gray-200">
//               <thead className="bg-gray-50">
//                 <tr>
//                   <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
//                   <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Test</th>
//                   <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
//                   <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
//                 </tr>
//               </thead>
//               <tbody className="divide-y divide-gray-200">
//                 {filteredPatients.map((item) => (
//                   <React.Fragment key={item.id}>
//                     <tr className="hover:bg-gray-100 transition-colors duration-200">
//                       <td className="px-4 py-3 text-sm font-medium text-gray-900">{item.name}</td>
//                       <td className="px-4 py-3 text-sm text-gray-600">{item.test}</td>
//                       <td className="px-4 py-3 text-sm text-gray-600">{item.amount}</td>
//                       <td className="px-4 py-3 text-sm text-gray-600">
//                         <button
//                           className="text-blue-600 hover:underline"
//                           onClick={() => toggleDetails(item.id)}
//                         >
//                           {showDetails[item.id] ? "Hide Details" : "Show Details"}
//                         </button>

                        
//                         <button
//                           className="text-green-600 hover:underline ml-2"
//                           onClick={() => handleEdit(item)}
//                         >
//                           Edit
//                         </button>
//                       </td>
//                     </tr>
//                     {showDetails[item.id] && (
//                       <tr className="bg-gray-50">
//                         <td colSpan="4" className="px-4 py-4">
//                           <div className="p-4 bg-white border border-gray-300 rounded-md">
//                             <p><strong>ID:</strong> {item.id}</p>
//                             <p><strong>Age:</strong> {item.age}</p>
//                             <p><strong>Doctor's Name:</strong> {item.dr_name}</p>
//                             <p><strong>Referred By:</strong> {item.ref_by}</p>
//                             <p><strong>Amount Paid:</strong> {item.amount_paid}</p>
//                             <p><strong>Amount Due:</strong> {item.amount_due}</p>
//                           </div>
//                         </td>
//                       </tr>
//                     )}
//                   </React.Fragment>
//                 ))}
//               </tbody>
//             </table>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PatientDataDisplay;

import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { BaseURl } from "../config/baseURL";

const PatientDataDisplay = () => {
  const [showDetails, setShowDetails] = useState({});
  const [patientDetails, setPatientDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noDataMessage, setNoDataMessage] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTest, setSelectedTest] = useState("");
  const [availableTests, setAvailableTests] = useState([]);
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });

  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [patientsPerPage] = useState(5); // Patients per page

  const navigate = useNavigate(); // Initialize navigate for routing

  const toggleDetails = (id) => {
    setShowDetails((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const fetchPatientDetails = async () => {
    const token = localStorage.getItem("token");
    console.log("log123  token --> ", token);

    setLoading(true);
    try {
      const response = await fetch(
        `${BaseURl}patients?date=${selectedDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          method: "GET",
        }
      );
      const data = await response.json();

      if (Array.isArray(data.data) && data.data.length > 0) {
        console.log("log123 --> ", data);
        
        setPatientDetails(data.data.reverse());
        setNoDataMessage(false);

        // Populate available tests
        const tests = data.data.reduce((acc, item) => {
          if (item.test && !acc.includes(item.test)) {
            acc.push(item.test);
          }
          return acc;
        }, []);
        setAvailableTests(tests);
      } else {
        setPatientDetails([]);
        setNoDataMessage(true);
      }
    } catch (error) {
      console.error("Failed to fetch patient data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      fetchPatientDetails();
    }
  }, [selectedDate]);

  // Calculate the filtered patients
  const filteredPatients = patientDetails.filter((item) => {
    const matchesName = item.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesTest = selectedTest === "" || item.test.toLowerCase().includes(selectedTest.toLowerCase());
    return matchesName && matchesTest;
  });

  // Pagination logic
  const indexOfLastPatient = currentPage * patientsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
  const currentPatients = filteredPatients.slice(indexOfFirstPatient, indexOfLastPatient);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to handle editing
  const handleEdit = (item) => {
    navigate(`/edit/${item.id}`, { state: { patient: item } }); // Navigate to edit page with patient data
  };

  return (
    <div className="w-full mx-auto m-5 p-5 font-sans bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-center text-gray-800 text-3xl font-bold mb-4">
        Patient Test Results
      </h1>

      {/* Filter Section */}
      <div className="mb-4 flex flex-wrap justify-between items-center space-y-4 md:space-y-0">
        {/* Search by Name */}
        <input
          type="text"
          placeholder="Search by Name"
          className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {/* Filter by Test */}
        <select
          className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-48"
          value={selectedTest}
          onChange={(e) => setSelectedTest(e.target.value)}
        >
          <option value="">Filter by Test</option>
          {availableTests.map((test, index) => (
            <option key={index} value={test}>
              {test}
            </option>
          ))}
        </select>

        {/* Filter by Date */}
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Table Section */}
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          {noDataMessage ? (
            <p className="text-center text-red-500 font-semibold p-4">
              No data available for the selected date.
            </p>
          ) : loading ? (
            <p className="text-center text-gray-500">Loading...</p>
          ) : (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Test</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {currentPatients.map((item) => (
                  <React.Fragment key={item.id}>
                    <tr className="hover:bg-gray-100 transition-colors duration-200">
                      <td className="px-4 py-3 text-sm font-medium text-gray-900">{item.name}</td>
                      <td className="px-4 py-3 text-sm text-gray-600">{item.test}</td>
                      <td className="px-4 py-3 text-sm text-gray-600">{item.amount}</td>
                      <td className="px-4 py-3 text-sm text-gray-600">
                        <button
                          className="text-blue-600 hover:underline"
                          onClick={() => toggleDetails(item.id)}
                        >
                          {showDetails[item.id] ? "Hide Details" : "Show Details"}
                        </button>

                        <button
                          className="text-green-600 hover:underline ml-2"
                          onClick={() => handleEdit(item)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                    {showDetails[item.id] && (
                      <tr className="bg-gray-50">
                        <td colSpan="4" className="px-4 py-4">
                          <div className="p-4 bg-white border border-gray-300 rounded-md">
                            <p><strong>ID:</strong> {item.id}</p>
                            <p><strong>Age:</strong> {item.age}</p>
                            <p><strong>Doctor's Name:</strong> {item?.doctor_details?.name}</p>
                            <p><strong>Referred By:</strong> {item?.ref_by_details?.name}</p>
                            <p><strong>Amount Paid:</strong> {item.amount_paid}</p>
                            <p><strong>Amount Due:</strong> {item.amount_due}</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        {[...Array(Math.ceil(filteredPatients.length / patientsPerPage)).keys()].map(number => (
          <button
            key={number + 1}
            onClick={() => paginate(number + 1)}
            className={`mx-1 px-3 py-1 rounded ${currentPage === number + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}
          >
            {number + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PatientDataDisplay;
