
import React from "react";
import { motion } from "framer-motion";
import Navbar from "../components/Navbar";
import PatientDataDisplay from "./PatientDataDisplay";

export default function PatientDetails() {
  return (
    <>
      <motion.div
        initial={{ x: "100vw" }}
        animate={{ x: 0 }}
        exit={{ x: "100vw" }}
        transition={{ type: "tween", duration: 0.6 }}
        className="relative min-h-screen bg-emerald-100 shadow-lg z-40 p-4 flex flex-col items-center justify-center" 
      >
        <div className="container mx-auto px-4 py-8 md:py-12 lg:py-16">
          <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold text-center mb-6">Patient Details</h2>
          
          {/* Responsive PatientDataDisplay component */}
          <div className="w-full md:w-4/5 lg:w-4/5 mx-auto">
            <PatientDataDisplay />
          </div>
        </div>
      </motion.div>
    </>
  );
}
