import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { BaseURl } from "../config/baseURL";

const AddDoctors = () => {
  const [tasks, setTasks] = useState([]);
  const [taskInput, setTaskInput] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getDoctors();
  }, []);

  const addTask = () => {
    if (taskInput.trim()) {
      addDoctorAPI(taskInput);
      setTaskInput("");
    }
  };

  const addDoctorAPI = async (taskInput) => {
    const formData = new FormData();
    formData.append("name", taskInput);
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${BaseURl}doctors`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Doctor added successfully:", data);
        getDoctors(); // Fetch the updated list of doctors
      } else {
        console.error("Error adding doctor:", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getDoctors = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${BaseURl}doctors`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Correct string interpolation here
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setTasks(data.data); // Update state with doctors data
      } else {
        console.error("Error fetching doctors:", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // Filter tasks based on name and date
  const filteredTasks = tasks.filter((task) => {
    const matchesName = task.name.toLowerCase().includes(nameFilter.toLowerCase());
    const matchesDate = dateFilter ? task.date.includes(dateFilter) : true;
    return matchesName && matchesDate;
  });

  const handleReferClick = (doctorName) => {
    navigate(`/patients-doctor/${doctorName}`, { state: { doctorName } });
  };

  return (
    <div className="bg-emerald-100 min-h-screen pt-5 pb-10">
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
        className="pt-16 max-w-lg lg:max-w-xl mx-auto bg-white rounded-lg shadow-md md:p-10 px-4 sm:px-6 md:px-7"
      >
        <h1 className="text-center text-3xl lg:text-4xl font-bold mb-4 text-gray-800">
          Add Doctors
        </h1>

        <div className="flex flex-col sm:flex-row mb-4">
          <input
            type="text"
            value={taskInput}
            onChange={(e) => setTaskInput(e.target.value)}
            className="flex-1 border border-gray-300 rounded-lg py-2 px-4 mb-4 sm:mb-0 sm:mr-4"
            placeholder="Add a new doctor..."
          />
          <button
            onClick={addTask}
            className="bg-emerald-500 text-white rounded-lg px-4 py-2 hover:bg-emerald-600"
          >
            Add
          </button>
        </div>

        {/* Filter inputs */}
        <div className="flex flex-col sm:flex-row mb-4">
          <input
            type="text"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            className="flex-1 border border-gray-300 rounded-lg py-2 px-4 mb-4 sm:mb-0 sm:mr-4"
            placeholder="Filter by name"
          />
          <input
            type="date"
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
            className="flex-1 border border-gray-300 rounded-lg py-2 px-4 mb-4 sm:mb-0"
          />
        </div>

        <ul className="list-disc pl-5 space-y-2">
          {filteredTasks.length > 0 ? (
            filteredTasks.map((task) => (
              <li
                key={task.id}
                className={`flex items-center justify-between mb-2 py-2 px-4 rounded-lg border ${
                  task.completed ? "bg-green-100" : "bg-gray-100"
                } transition-colors`}
                onClick={() => handleReferClick(task)} // Pass the doctor's name
              >
                <span
                  className={`flex-1 cursor-pointer ${
                    task.completed
                      ? "line-through text-gray-500"
                      : "text-gray-800"
                  }`}
                >
                  {task.name}
                </span>
              </li>
            ))
          ) : (
            <p className="text-center text-gray-600">No doctors available</p>
          )}
        </ul>
      </motion.div>
    </div>
  );
};

export default AddDoctors;
